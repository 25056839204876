<template>
  <v-app id="inspire">
    <v-navigation-drawer app clipped v-model="drawer">
      <content-navigation />
    </v-navigation-drawer>

    <v-app-bar app clipped-left height="80">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title
        class="font-weight-black headline"
        style="cursor: pointer"
        @click="$router.push('/contents/dashboard')"
      >
        EV Infra for OCPP
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <content-app-bar />
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>

      <vue-confirm-dialog></vue-confirm-dialog>
    </v-main>

    <v-footer app class="justify-center" color="#292929" height="60">
      <content-footer />
    </v-footer>
  </v-app>
</template>

<script>
import ContentAppBar from "@/app/contents/ContentAppBar.vue";
import ContentNavigation from "@/app/contents/ContentNavigation.vue";
import ContentFooter from "@/app/contents/ContentFooter.vue";

export default {
  name: "Home",
  components: {
    ContentAppBar,
    ContentNavigation,
    ContentFooter,
  },

  data: () => ({
    drawer: true,
  }),

  methods: {},

  mounted() {
    this.$vuetify.theme.dark = true;
  },
};
</script>

<style></style>
