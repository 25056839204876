<template>
  <div class="title font-weight-light grey--text text--lighten-1 text-center">
    Copyright &copy; {{ new Date().getFullYear() }} SOFT BERRY Inc. all rights
    reserved.
  </div>
</template>

<script>
export default {};
</script>

<style></style>
