<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on">
        {{ nickname }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item to="/contents/usernames" link>
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>내 정보 설정</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/account/logout" link>
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>로그아웃</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import Authorization from "@/app/env.authorization.js";

export default {
  data: () => ({
    nickname: null,
    avatar: null,
  }),

  mounted() {
    const data = Authorization.getAuthorizationData();
    this.nickname = data.nickname;
    this.provider = data.provider;

    if ("facebook" === data.provider) {
      this.avatar = "@/assets/facebook2.png";
      // }else if('google' === data.provider){
    }

    //Authorization.getNickname();
  },
};
</script>

<style></style>
