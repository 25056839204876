<template>
  <div>
    <v-list
      class="my-0 py-0"
      v-for="navigation in navigations"
      :key="navigation.subheader"
    >
      <v-divider v-if="navigation.divider"></v-divider>
      <v-subheader inset v-if="navigation.subheader">{{
        navigation.subheader
      }}</v-subheader>
      <v-list-item
        v-for="[title, href, icon] in navigation.items"
        :key="title"
        :to="href"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  data: () => ({
    navigations: [
      {
        items: [["Dashboard", "/contents/dashboard", "mdi-home"]],
      },
      {
        divider: true,
        subheader: "정보",
        items: [
          ["충전 스테이션", "/contents/managements", "mdi-map-marker-radius"],
          ["충전 설비", "/contents/equipments", "mdi-ev-station"],
          ["과금 정책", "/contents/prices", "mdi-database-cog"],
        ],
      },
      {
        divider: true,
        subheader: "운영",
        items: [
          ["충전 내역", "/contents/events", "mdi-car-electric"],
          ["충전 통계", "/contents/statistics", "mdi-chart-bar"],
        ],
      },
      {
        divider: true,
        subheader: "도움말",
        items: [
          ["QnA", "/contents/qna", "mdi-help-circle"],
          ["1:1문의", "/contents/inquiries", "mdi-cloud-question"],
        ],
      },
      {
        divider: true,
        subheader: "설정",
        items: [
          ["대시보드 설정", "/contents/settings", "mdi-application-cog"],
          ["사용자 정보", "/contents/usernames", "mdi-account-cog"],
        ],
      },
      {
        divider: true,
        items: [["로그아웃", "/account/logout", "mdi-logout"]],
      },
    ],
  }),
};
</script>

<style></style>
